import { BrowserModule } from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClient } from '@angular/common/http';
import { HttpModule } from '@angular/http';
import { FormsModule } from '@angular/forms';

// Translate
import {TranslateLoader, TranslateModule, TranslateStore} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

// PrimeNG
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { MessagesModule } from 'primeng/messages';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { PasswordModule } from 'primeng/password';
import { DropdownModule } from 'primeng/dropdown';

import { AppRoutingModule } from './app-routing.module';

import { AuthService } from './core/services/auth.service';
import { ThemeService } from './core/services/theme.service';

import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';

import { HttpConfigInterceptor } from './core/services/http-interceptor';
import { ApiDownComponent } from './api-down/api-down.component';
import { ErrorHttpConfigInterceptor } from './core/services/error-http-interceptor';
import { TokenAccessErrorComponent } from './token-access-error/token-access-error.component';

// required for AOT compilation
export function httpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ApiDownComponent,
    TokenAccessErrorComponent,
  ],
  imports: [
    BrowserModule,
    HttpModule,
    FormsModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    // CoreModule,
    HttpClientModule,
    // Translate
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient): TranslateHttpLoader => {
          return new TranslateHttpLoader(http);
        },
        deps: [HttpClient]
      }
    }),
    // PrimeNG
    ButtonModule,
    InputTextModule,
    PasswordModule,
    ProgressSpinnerModule,
    MessagesModule,
    DropdownModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpConfigInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHttpConfigInterceptor,
      multi: true
    },
    TranslateStore,
    AuthService,
    ThemeService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
